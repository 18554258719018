import {AxiosResponse} from "axios";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {IApiResponse, type IHttpClientService} from "data/services/http";
import {IGameBar, IGameBarNRLW, IOverallDashboardResponse} from "data/types/game";
import {ILeagueRanking} from "data/stores/league_rankings/league_rankings.store";
import {IRanking} from "data/stores/rankings/rankings.store";
import {OrderField, SortOrder} from "data/enums";

interface IGetGameBarPayload {
	roundId?: number;
}

export interface ILeagueRankingsPayload {
	roundId?: number;
	leagueId: number;
	page?: number;
	limit?: number;
	showPredictions?: "0" | "1";
	sortBy?: OrderField;
	sortOrder?: SortOrder;
}

export interface IRankingsPayload {
	roundId?: number;
	page?: number;
	limit?: number;
	state?: string;
	supportedSquadId?: string;
	showPredictions?: boolean;
}

export interface ILeagueRankingsResponse {
	rankings: ILeagueRanking[];
	user: ILeagueRanking | null;
	nextPage: boolean;
}

export interface IRankingsResponse {
	rankings: IRanking[];
	user: IRanking | null;
	nextPage: boolean;
}

export type TGameBarResponse = IApiResponse<IGameBar>;

export type TGameBarNRLWResponse = IApiResponse<IGameBarNRLW>;

export type TDashboardResponse = IApiResponse<IOverallDashboardResponse>;

export type TLeagueRankingsResponse = IApiResponse<ILeagueRankingsResponse>;

export type TRankingsResponse = IApiResponse<IRankingsResponse>;

export interface IRankingApiProvider {
	gameBar: (payload?: IGetGameBarPayload) => Promise<AxiosResponse<TGameBarResponse>>;
	league: (params: ILeagueRankingsPayload) => Promise<AxiosResponse<TLeagueRankingsResponse>>;
	overall: (params: IRankingsPayload) => Promise<AxiosResponse<TRankingsResponse>>;
	gameBarNRLW: () => Promise<AxiosResponse<TGameBarNRLWResponse>>;
	dashboard: () => Promise<AxiosResponse<TDashboardResponse>>;
}

@injectable()
export class RankingApiProvider implements IRankingApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	gameBar = (params?: IGetGameBarPayload) => {
		const queryParams = params?.roundId ? `/${params.roundId}` : "";
		return this._http.get<TGameBarResponse>(`tipping/ranking/gamebar${queryParams}`);
	};

	league = (params: ILeagueRankingsPayload) =>
		this._http.get<TLeagueRankingsResponse>("tipping/ranking", params);

	overall = (params: IRankingsPayload) =>
		this._http.get<TRankingsResponse>("tipping/ranking/overall", params);

	gameBarNRLW = () => {
		return this._http.get<TGameBarNRLWResponse>(`tipping/ranking/gamebar_nrlw`);
	};

	dashboard = () => {
		return this._http.get<TDashboardResponse>(`tipping/ranking/dashboard`);
	};
}
