import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {DateTime} from "luxon";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {ModalType, RequestState, RoundStatus} from "data/enums";
import {type IUserStore} from "data/stores/user/user.store";
import {type IRankingApiProvider} from "data/providers/api/ranking.api.provider";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {ITippingGameBarNRLW} from "data/types/game";

export interface INRLWTippingGameController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isAuthorized(): boolean;
	get currentRoundId(): number;
	get isShowScheduledBlock(): boolean;
	get currentRoundStartDate(): string;
	get isPlaying(): boolean;
	get isComplete(): boolean;
	get isLoading(): boolean;
	get isUserRegistered(): boolean;
	get tippingGameBaNRLW(): ITippingGameBarNRLW;
}

@injectable()
export class NRLWTippingGameController implements INRLWTippingGameController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _isUserRegistered = false;
	@observable private _tippingGameBarNRLW: ITippingGameBarNRLW = {
		roundRank: null,
		roundPoints: null,
		overallPoints: null,
		overallRank: null,
		prevOverallRank: null,
		tips: 0,
		tipsCount: 0,
		rankRoundId: 1,
		correctTips: 0,
		rankRoundStatus: "",
		rankRoundStartDate: "",
	};

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get isUserRegistered() {
		return Boolean(this._isUserRegistered);
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get currentRoundId() {
		return this._tippingGameBarNRLW.rankRoundId;
	}

	get currentRoundStartDate() {
		return this._tippingGameBarNRLW.rankRoundStartDate || "";
	}

	get isScheduled() {
		return this._tippingGameBarNRLW?.rankRoundStatus === RoundStatus.Scheduled;
	}

	get isShowScheduledBlock() {
		if (!this.currentRoundStartDate) {
			return false;
		}

		const startDate = DateTime.fromISO(this.currentRoundStartDate);
		return this.isScheduled && startDate > DateTime.now();
	}

	get isPlaying() {
		return this._tippingGameBarNRLW?.rankRoundStatus === RoundStatus.Playing;
	}

	get isComplete() {
		return this._tippingGameBarNRLW?.rankRoundStatus === RoundStatus.Complete;
	}

	get tippingGameBaNRLW() {
		return this._tippingGameBarNRLW;
	}

	constructor(
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RankingApiProvider) private _rankingApi: IRankingApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	async init() {
		try {
			this._requestState = RequestState.PENDING;

			if (this.isAuthorized) {
				const requests = [this._rankingApi.dashboard()];

				const [overallDashboard] = await Promise.all(requests);

				runInAction(() => {
					this._isUserRegistered = Boolean(
						overallDashboard.data.success.isNRLWUserRegistered
					);
					if (overallDashboard.data.success.tippingNRLWGameBar) {
						this._tippingGameBarNRLW = overallDashboard.data.success.tippingNRLWGameBar;
					}
				});
			}

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this._requestState = RequestState.ERROR;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	}
}
