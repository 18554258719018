import {FC, Fragment} from "react";
import {Outlet} from "react-router-dom";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {footerLinks} from "data/constants/navigation";
import {Header} from "views/components/header/header/header.component";
import {Footer} from "views/components/footer/footer.component";
import {ILandingLayoutController} from "./landing_layout.controller";
import {ReactComponent as LogoIcon} from "assets/img/nrlHub.svg";

export const LandingLayout: FC = observer(() => {
	const {subNavItems, burgerNavItems} = useViewController<ILandingLayoutController>(
		Bindings.LandingLayoutController
	);

	return (
		<Fragment>
			<Header
				LogoIcon={LogoIcon}
				subNavItems={subNavItems}
				burgerNavItems={burgerNavItems}
				isHideMobileMenu
				isHideSubNav
			/>
			<Outlet />
			<Footer links={footerLinks} />
		</Fragment>
	);
});
