import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {get} from "lodash";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {ITournamentData, ITournamentStore} from "data/stores/tournament/tournament.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import {type ISportsbetStore, SportsbetType} from "data/stores/sportsbet/sportsbet.store";
import {
	ILastMeetings,
	type ITournamentStatsStore,
} from "data/stores/tournament_stats/tournament_stats.store";
import {ModalType} from "data/enums";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

interface IProps {
	tournamentId: number;
}

export interface IGameStatsCardController extends ViewController<IProps> {
	i18n: ILocalizationStore;

	get tournamentData(): ITournamentData | null;
	get isShowOdds(): boolean;
	get lastMeetings(): ILastMeetings[];
	get isLoading(): boolean;
	get matchUpLink(): string;

	changeHandler: (squadId: number) => void;
}

@injectable()
export class GameStatsCardController implements IGameStatsCardController {
	@observable private _tournamentId: number = 0;

	private get prediction() {
		return this._predictionsStore.getPredictionByTournamentId(this._tournamentId);
	}

	get tournamentData() {
		return this._tournamentStore.getTournamentDataById(this._tournamentId, this.prediction);
	}

	get isShowOdds() {
		const isShowMatchOdds = this._sportsbetStore.getIsShow(SportsbetType.MatchOdds);

		return this._tournamentStore.getIsShowOddsTournament(this.tournamentData, isShowMatchOdds);
	}

	private get stats() {
		return this._tournamentStatsStore.getTournamentStats(this._tournamentId);
	}

	get lastMeetings() {
		return this.stats?.lastMeetings || [];
	}

	get isLoading() {
		return !this.stats;
	}

	get matchUpLink() {
		const roundId = get(this._roundsStore.selectedRound, "id", "");
		const homeName = get(this.tournamentData?.homeSquad, "name", "")
			.toLowerCase()
			.split(" ")
			.join("-");
		const awayName = get(this.tournamentData?.awaySquad, "name", "")
			.toLowerCase()
			.split(" ")
			.join("-");
		return `https://www.nrl.com/draw/nrl-premiership/2024/round-${roundId}/${homeName}-v-${awayName}/`;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SportsbetStore) private _sportsbetStore: ISportsbetStore,
		@inject(Bindings.TournamentStatsStore) private _tournamentStatsStore: ITournamentStatsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private readonly _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	async init(param: IProps) {
		this._tournamentId = param.tournamentId;

		try {
			await this._tournamentStatsStore.fetchTournamentStat(param.tournamentId);
		} catch (err) {
			console.error(err);
		}
	}

	changeHandler = async (squadId: number) => {
		try {
			if (this.tournamentData?.isDisabled) {
				return;
			}

			await this._predictionsStore.setPredictionSquad(this._tournamentId, squadId);

			const isShowModal =
				this._predictionsStore.hasAllPredictions &&
				!this._predictionsStore.allPredictionWereSaved;

			if (isShowModal) {
				this._modalsStore.showModal(ModalType.PREDICTIONS_SAVED);
			}

			const roundId = this._roundsStore.selectedRound?.id;

			if (roundId) {
				this._predictionsStore.updateAllPredictionWereSaved(
					roundId,
					this._predictionsStore.predictions
				);
			}
		} catch (error) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	};
}
