import React, {FC} from "react";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";
import {NavLink} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IBurgerNavItem} from "data/types/navigation";
import {burgerMenuGameLinks} from "data/constants/navigation";
import {ButtonPure} from "views/components/buttons/buttons.component";
import {Exist} from "views/components/exist/exist.component";
import {BurgerNavLink} from "./burger_nav_link/burger_nav_link.component";
import {IBurgerNavController} from "./burger_nav.controller";
import {ReactComponent as BurgerMenuLogo} from "assets/img/icons/burgerMenuLogo.svg";
import {ReactComponent as AccountIcon} from "assets/img/icons/account.svg";
import {ReactComponent as ExternalLinkIcon} from "assets/img/icons/externalLink.svg";

const Wrapper = styled.nav`
	// display: none;
	flex-direction: column;
	align-items: center;
	padding: 40px 24px;
	position: absolute;
	width: 100%;
	height: 100vh;
	background: ${({theme}) => theme.palette.header.burgerMenuBg};
	-webkit-font-smoothing: antialiased;
	transform-origin: 0% 0%;
	transform: translate(-100%, 0);
	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
	z-index: 10;
	top: 0;
	overflow: auto;
	visibility: hidden;

	&.open {
		transform: none;
		display: flex;
		visibility: visible;
	}

	@media (min-width: 769px) {
		max-width: 300px;
	}
`;

const CloseButton = styled(IconButton)`
	color: ${({theme}) => theme.palette.header.burgerMenuCloseBtnColor};
	position: fixed;
	right: 16px;
	top: 16px;

	@media (min-width: 769px) {
		right: auto;
		left: 250px;
	}
`;

const LogoWrapper = styled.div`
	margin-bottom: 44px;
`;

const Links = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 80px;
`;

const Button = styled(ButtonPure)`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	color: ${({theme}) => theme.palette.header.burgerMenuLinkColor};
	min-height: 40px;
	padding: 0 8px;
	font-family: "RL2", sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 12px;
	letter-spacing: 3px;
`;

const Link = Button.withComponent(NavLink);
const ExternalLink = Button.withComponent("a");

const label = (link: string) => {
	switch (link) {
		case "fantasy":
			return "Visit NRL Fantasy";
			break;
		case "fantasy_news":
			return "View NRL Fantasy News";
			break;
		case "draft":
			return "Visit NRL Fantasy Draft";
			break;
		case "nrl":
			return "Visit NRL.com";
			break;
		case "ladder_predictor":
			return "Visit NRL Ladder Predictor";
			break;
		default:
			return "Visit NRL.com";
	}
};

interface IBurgerNav {
	isOpen: boolean;
	closeHandler: () => void;
	items: IBurgerNavItem[];
}

export const BurgerNav: FC<IBurgerNav> = ({isOpen, closeHandler, items}) => {
	const {i18n, logout, isAuthorized} = useViewController<IBurgerNavController>(
		Bindings.BurgerNavController
	);
	const className = isOpen ? "open" : "";

	const logoutHandler = () => {
		closeHandler();
		void logout();
	};

	return (
		<Wrapper className={className}>
			<CloseButton aria-label="Close Menu" onClick={closeHandler}>
				<CloseIcon />
			</CloseButton>

			<LogoWrapper>
				<BurgerMenuLogo />
			</LogoWrapper>

			<Links>
				{items.map((link) => (
					<BurgerNavLink {...link} key={link.locoKey} closeHandler={closeHandler} />
				))}
			</Links>

			<Links>
				{burgerMenuGameLinks.map(({locoKey, defaultValue, href}) => {
					const link = locoKey.split(".")[1];
					return (
						<ExternalLink
							href={href}
							key={locoKey}
							target="_blank"
							rel="noopener noreferrer">
							<div>{i18n.t(locoKey, defaultValue)}</div>

							<IconButton aria-label={label(link)}>
								<ExternalLinkIcon />
							</IconButton>
						</ExternalLink>
					);
				})}
			</Links>

			<Exist when={isAuthorized}>
				<Link to="/my-account" onClick={closeHandler}>
					<div>{i18n.t("navigation.my_account.copy", "My Account")}</div>

					<IconButton aria-label="Visit My Account Page">
						<AccountIcon />
					</IconButton>
				</Link>

				<Button onClick={logoutHandler}>
					{i18n.t("navigation.logout.copy", "Logout")}
				</Button>
			</Exist>
		</Wrapper>
	);
};
