import {IBurgerNavItem, ISubNavItem} from "data/types/navigation";

const tipsSubNavItem: ISubNavItem = {
	defaultValue: "My Tips",
	locoKey: "navigation.my_tips.copy",
	path: "/my-tips",
	isPrivate: true,
};

const helpLocoKey = "navigation.help.copy";

const rankingsLocoKey = "navigation.rankings.copy";

const nrlwTippingLocoKey = "navigation.nrlw_tipping.copy";

const helpSubItem: ISubNavItem = {
	defaultValue: "Help",
	locoKey: helpLocoKey,
	path: "/help",
	isDesktopOnly: true,
};

export const subNavigation: ISubNavItem[] = [
	tipsSubNavItem,
	{
		defaultValue: "Comps",
		locoKey: "navigation.comps.copy",
		path: "/comps",
		isPrivate: true,
	},
	{
		defaultValue: "Rankings",
		locoKey: rankingsLocoKey,
		path: "/rankings",
		isPrivate: true,
	},
	{
		defaultValue: "Help",
		locoKey: helpLocoKey,
		path: "/help",
	},
	{
		defaultValue: "NRLW Tipping",
		locoKey: nrlwTippingLocoKey,
		path: "https://nrlwtipping.nrl.com/my-tips",
	},
];

export const burgerMenuLinks: IBurgerNavItem[] = [
	{
		defaultValue: "My Tips",
		locoKey: "navigation.my_tips.copy",
		path: "/my-tips",
		isPrivate: true,
	},
	{
		defaultValue: "Streak",
		locoKey: "navigation.streak.copy",
		path: "/streak/my-tips",
		isPrivate: true,
	},
	{
		defaultValue: "Comps",
		locoKey: "navigation.comps.copy",
		path: "/comps",
		isPrivate: true,
	},
	{
		defaultValue: "Rankings",
		locoKey: rankingsLocoKey,
		path: "/rankings",
		isPrivate: true,
	},
	{
		defaultValue: "Help",
		locoKey: helpLocoKey,
		path: "/help",
		subLinks: [
			{
				defaultValue: "How to play",
				locoKey: "navigation.help.guidelines_copy",
				path: "/help/game-guidelines",
			},
			{
				defaultValue: "FAQs",
				locoKey: "navigation.help.faqs_copy",
				path: "/help/competition-faqs",
			},
			{
				defaultValue: "Terms",
				locoKey: "navigation.help.terms_copy",
				path: "/help/terms-and-conditions",
			},
			{
				defaultValue: "Prizes",
				locoKey: "navigation.help.prizes_copy",
				path: "/help/prizes",
			},
			{
				defaultValue: "Contact us",
				locoKey: "navigation.help.contact_us_copy",
				path: "/help/contact-us",
			},
		],
	},
];

export const footerLinks = [
	{
		defaultValue: "Terms & Conditions",
		locoKey: "footer.link.terms",
		path: "/help/terms-and-conditions",
	},
	{
		defaultValue: "FAQ",
		locoKey: "footer.link.faqs_copy",
		path: "/help/competition-faqs",
	},
	{
		defaultValue: "Game Guilelines",
		locoKey: "footer.link.guidelines_copy",
		path: "/help/game-guidelines",
	},
	{
		defaultValue: "Privacy",
		locoKey: "footer.link.privacy",
		path: "https://www.nrl.com/privacy-policy",
	},
];

export const streakFooterLinks = [
	{
		defaultValue: "Terms & Conditions",
		locoKey: "footer.link.terms",
		path: "/streak/help/terms-and-conditions",
	},
	{
		defaultValue: "FAQ",
		locoKey: "footer.link.faqs_copy",
		path: "/streak/help/competition-faqs",
	},
	{
		defaultValue: "Game Guilelines",
		locoKey: "footer.link.guidelines_copy",
		path: "/streak/help/game-guidelines",
	},
	{
		defaultValue: "Privacy",
		locoKey: "footer.link.privacy",
		path: "https://www.nrl.com/privacy-policy",
	},
];

export const landingSubNavigation: ISubNavItem[] = [tipsSubNavItem, /*streakSubItem,*/ helpSubItem];

export const burgerMenuGameLinks = [
	{
		defaultValue: "Fantasy",
		locoKey: "navigation.fantasy.copy",
		href: "https://fantasy.nrl.com/",
	},
	{
		defaultValue: "Fantasy News",
		locoKey: "navigation.fantasy_news.copy",
		href: "https://www.nrl.com/news/topic/fantasy/",
	},
	{
		defaultValue: "Draft",
		locoKey: "navigation.draft.copy",
		href: "https://fantasy.nrl.com/draft/",
	},
	{
		defaultValue: "NRL Home",
		locoKey: "navigation.nrl.copy",
		href: "https://nrl.com/",
	},
	{
		defaultValue: "Ladder Predictor",
		locoKey: "navigation.ladder_predictor.copy",
		href: "https://ladderpredictor.nrl.com/",
	},
];

export const streakSubNavigation: ISubNavItem[] = [
	{
		defaultValue: "Streak",
		locoKey: "navigation.streak.copy",
		path: "/streak/my-tips",
		isPrivate: true,
	},
	{
		defaultValue: "Rankings",
		locoKey: rankingsLocoKey,
		path: "/streak/rankings",
		isPrivate: true,
	},
	{
		defaultValue: "Help",
		locoKey: helpLocoKey,
		path: "/streak/help",
	},
];
