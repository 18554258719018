export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	PredictionApiProvider: Symbol("PredictionApiProvider"),
	StreakPredictionApiProvider: Symbol("StreakPredictionApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	RankingsStore: Symbol("RankingsStore"),
	TutorialStore: Symbol("TutorialStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	ModalCompleteRoundStore: Symbol("ModalCompleteRoundStore"),
	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	RegistrationController: Symbol("RegistrationController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	MyTipsController: Symbol("MyTipsController"),
	SubNavController: Symbol("SubNavController"),
	TippingBannerController: Symbol("TippingBannerController"),
	RoundSelectorController: Symbol("RoundSelectorCntroller"),
	TippingCardController: Symbol("TippingCardController"),
	LoginCallbackController: Symbol("LoginCallbackController"),
	StatesStore: Symbol("StatesStore"),
	NrlIdApiProvider: Symbol("NrlIdApiProvider"),
	HeaderController: Symbol("HeaderController"),
	BurgerNavLinkController: Symbol("BurgerNavLinkController"),
	BurgerNavController: Symbol("BurgerNavController"),
	TopActionsController: Symbol("TopActionsController"),
	PredictionsStore: Symbol("PredictionsStore"),
	TipsController: Symbol("TipsController"),
	JoinLeaguesCardController: Symbol("JoinLeaguesCardController"),
	LeaguesSearchController: Symbol("LeaguesSearchController"),
	TipsProfileController: Symbol("TipsProfileController"),
	ModalAutoPickController: Symbol("ModalAutoPickController"),
	InviteLeagueCardController: Symbol("InviteLeagueCardController"),
	LeagueCardController: Symbol("LeagueCardController"),
	RegenerateLeagueCardController: Symbol("RegenerateLeagueCardController"),
	LeagueTableController: Symbol("LeagueTableController"),
	ModalPredictionsSavedController: Symbol("ModalPredictionsSavedController"),
	ModalCompleteRoundController: Symbol("ModalCompleteRoundController"),
	RankingApiProvider: Symbol("RankingApiProvider"),
	StreakRankingApiProvider: Symbol("StreakRankingApiProvider"),
	LadderController: Symbol("LadderController"),
	ModalShareController: Symbol("ModalShareController"),
	TippingLayoutController: Symbol("TippingLayoutController"),
	LandingLayoutController: Symbol("LandingLayoutController"),
	MobileHeaderController: Symbol("MobileHeaderController"),
	SportsbetStore: Symbol("SportsbetStore"),
	SportbetLayoutController: Symbol("SportbetLayoutController"),
	OddsController: Symbol("OddsController"),
	GameBarController: Symbol("GameBarController"),
	GameBarStore: Symbol("GameBarStore"),
	BannerStore: Symbol("BannerStore"),
	LeagueRankingsStore: Symbol("LeagueRankingsStore"),
	TippingGameController: Symbol("TippingGameController"),
	RankingsController: Symbol("RankingsController"),
	TutorialController: Symbol("TutorialController"),
	TutorialStepController: Symbol("TutorialStepController"),
	ShareApiProvider: Symbol("ShareApiProvider"),
	MonthsStore: Symbol("MonthsStore"),
	RankingsFiltersController: Symbol("RankingsFiltersController"),
	TableRowController: Symbol("TableRowController"),
	RankingsTableController: Symbol("RankingsTableController"),
	ModalJoinLeagueInviteController: Symbol("ModalJoinLeagueInviteController"),
	ModalInviteViaEmailController: Symbol("ModalInviteViaEmailController"),
	ModalGameStatsController: Symbol("ModalGameStatsController"),
	LeagueTableRowController: Symbol("LeagueTableRowController"),
	LeagueTableFiltersController: Symbol("LeagueTableFiltersController"),
	GameStatsCardController: Symbol("GameStatsCardController"),
	AutoPickStore: Symbol("AutoPickStore"),
	GameBannerController: Symbol("GameBannerController"),
	TournamentStore: Symbol("TournamentStore"),
	AdvertController: Symbol("AdvertController"),
	AdsLayoutController: Symbol("AdsLayoutController"),
	TournamentStatsStore: Symbol("TournamentStatsStore"),
	PreviousMeetingsController: Symbol("PreviousMeetingsController"),
	TournamentStatsCircleController: Symbol("TournamentStatsCircleController"),
	StreakLayoutController: Symbol("StreakLayoutController"),
	StreakController: Symbol("StreakController"),
	StreakPredictionsStore: Symbol("StreakPredictionsStore"),
	StreakGameBarStore: Symbol("StreakGameBarStore"),
	StreakTopActionsController: Symbol("StreakTopActionsController"),
	StreakGameBarController: Symbol("StreakGameBarController"),
	StreakTipsController: Symbol("StreakTipsController"),
	StreakCardController: Symbol("StreakCardController"),
	StreakBannerController: Symbol("StreakBannerController"),
	StreakProgressController: Symbol("StreakProgressController"),
	ModalStreakPredictionsSavedController: Symbol("ModalStreakPredictionsSavedController"),
	StreakRankingsStore: Symbol("StreakRankingsStore"),
	StreakRankingsController: Symbol("StreakRankingsController"),
	StreakRankingsTableController: Symbol("StreakRankingsTableController"),
	StreakRankingsTableRowController: Symbol("StreakRankingsTableRowController"),
	StreakGameController: Symbol("StreakGameController"),
	NRLWTippingGameController: Symbol("NRLWTippingGameController"),
	FormGuideController: Symbol("FormGuideController"),
	KeyChangesController: Symbol("KeyChangesController"),
	TeamStatsController: Symbol("TeamStatsController"),
	NotificationBarStore: Symbol("NotificationBarStore"),
	NotificationBarController: Symbol("NotificationBarController"),
};
