import React from "react";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {tournamentIcons} from "data/constants/game";
import {getSquadAvatarUrl} from "data/utils/avatar";
import {ModalBase, ModalTitle} from "views/components/modals/modal_base/modal_base.component";
import {Exist} from "views/components/exist/exist.component";
import {
	List,
	SquadLogo,
	SquadName,
	Tournament,
	TournamentIcon,
} from "views/components/modals/modal_predictions_saved/modal_predictions_saved.component";
import {IModalCompleteRoundController} from "./modal_complete_round.controller";
import {ReactComponent as ModalProgressIcon} from "assets/img/icons/modalProgress.svg";
import {ReactComponent as ModalSuccessIcon} from "assets/img/icons/modalSuccess.svg";
import startsImg from "assets/img/stars.svg";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 18px;
	max-width: 380px;
	width: 100%;
	position: relative;
`;

const Stats = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 12px;
	margin-top: 10px;
`;

const StatItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	gap: 4px;
`;

const Value = styled.div`
	font-size: 16px;
	font-weight: 500;
	line-height: 18px;
`;

const Stars = styled.img`
	width: 185px;
	top: -65px;
	height: auto;
	position: absolute;
	z-index: -1;
`;

export const ModalCompleteRound: React.FC = observer(() => {
	const {
		i18n,
		isOpen,
		close,
		roundId,
		isPerfect,
		tournaments,
		nextRoundId,
		pickRoundHandler,
		gameBar,
	} = useViewController<IModalCompleteRoundController>(Bindings.ModalCompleteRoundController);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close} className="confirm-modal">
			<Container>
				<Exist when={isPerfect}>
					<ModalSuccessIcon />
					<Stars src={startsImg} />
				</Exist>

				<Exist when={!isPerfect}>
					<ModalProgressIcon />
				</Exist>

				<ModalTitle>
					{i18n.t("tips.complete.title", "Your Round {{X}} Results", {X: roundId})}
				</ModalTitle>

				<Stats>
					<StatItem>
						<Value>{gameBar.roundPoints ?? "-"}</Value>
						<div>{i18n.t("tips.complete.round_pts", "Round Pts")}</div>
					</StatItem>

					<StatItem>
						<Value>{gameBar.roundRank ?? "-"}</Value>
						<div>{i18n.t("tips.complete.week_rank", "Round Rank")}</div>
					</StatItem>

					<StatItem>
						<Value>{gameBar.overallRank ?? "-"}</Value>
						<div>{i18n.t("tips.complete.overall_rank", "Overall Rank")}</div>
					</StatItem>
				</Stats>

				<List className="max-h-250">
					{tournaments.map(
						({
							id,
							awayIconType,
							homeIconType,
							homeSquadId,
							awaySquadId,
							homeSquadName,
							awaySquadName,
							isSelectedAwaySquad,
							isSelectedHomeSquad,
						}) => {
							const HomeIcon = tournamentIcons[homeIconType];
							const AwayIcon = tournamentIcons[awayIconType];

							return (
								<Tournament key={id}>
									<TournamentIcon>
										<Exist when={isSelectedHomeSquad}>
											<HomeIcon />
										</Exist>
									</TournamentIcon>

									<SquadLogo
										src={getSquadAvatarUrl(homeSquadId)}
										alt={homeSquadName}
									/>

									<SquadName>{homeSquadName}</SquadName>

									<SquadName className="reverse">{awaySquadName}</SquadName>

									<SquadLogo
										src={getSquadAvatarUrl(awaySquadId)}
										alt={awaySquadName}
									/>

									<TournamentIcon>
										<Exist when={isSelectedAwaySquad}>
											<AwayIcon />
										</Exist>
									</TournamentIcon>
								</Tournament>
							);
						}
					)}
				</List>

				<Exist when={nextRoundId}>
					<Button variant="contained" color="primary" onClick={pickRoundHandler}>
						{i18n.t("tips.complete.button", "Pick round {{X}}", {X: nextRoundId})}
					</Button>
				</Exist>
			</Container>
		</ModalBase>
	);
});
