/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {PaletteColorOptions, ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface CustomPalette {
		apple: PaletteColorOptions;
		landingPage: {
			bg: string;
			color: string;
			cardBg: string;
			cardColor: string;
		};
		tipsPage: {
			bg: string;
		};
		leagues: {
			bg: string;
			primary: string;
			secondary: string;
			tint: string;
		};
		header: {
			bg: string;
			navBorderBottom: string;
			subNavLinkColor: string;
			subNavLinkActiveColor: string;
			subNavLinkActiveBorderColor: string;
			burgerMenuBg: string;
			burgerMenuCloseBtnColor: string;
			burgerMenuLinkColor: string;
		};
		login: {
			bg: string;
			color: string;
		};
		footer: {
			bg: string;
			color: string;
			linkColor: string;
		};
		banner: {
			liveBg: string;
			liveColor: string;
			liveCloseBg: string;
			timerBg: string;
			timerColor: string;
			timerCloseBg: string;
			savingBg: string;
			savingColor: string;
			savingCloseBg: string;
			savedBg: string;
			savedColor: string;
			savedCloseBg: string;
		};
		roundSelector: {
			color: string;
			bg: string;
		};
		tipsCard: {
			bg: string;
			dateColor: string;
			color: string;
			marginBg: string;
			oddsBg: string;
			oddsColor: string;
			liveBg: string;
			liveColor: string;
		};
		tipsProfile: {
			linkColor: string;
		};
		modal: {
			bg: string;
			color: string;
		};
		ladder: {
			color: string;
			slashColor: string;
			divider: string;
			bg: string;
		};
		account: {
			bg: string;
			formBg: string;
		};
		sportsbetCalculator: {
			bg: string;
			multiBg: string;
			color: string;
			stakeBg: string;
			stakeColor: string;
		};
		help: {
			color: string;
			bg: string;
			navColor: string;
			navBg: string;
			navActiveBg: string;
			contentColor: string;
			contentBg: string;
		};
		rankings: {
			bg: string;
			color: string;
			tableBg: string;
			tableColor: string;
			tableHeadBg: string;
			tableHeadColor: string;
			tableUserBg: string;
		};
		tutorial: {
			color: string;
			bg: string;
			paginationBg: string;
			activePaginationBg: string;
		};
		secret: {
			headerBg: string;
			bg: string;
			contentBg: string;
			titleColor: string;
			subTitleColor: string;
			descColor: string;
			descHoverColor: string;
			descHoverBg: string;
			welcomeBackColor: string;
			welcomeBackBg: string;
		};
		tournamentStatsModal: {
			bg: string;
			dots: string;
			activeDots: string;
		};
		statsModal: {
			bg: string;
			statsBg: string;
			color: string;
			linkBg: string;
			statsItemBg: string;
		};
		/**
		 * Streak progress bar
		 */
		chaserBar: {
			primary: string;
			secondary: string;
			active: string;
		};
	}

	interface Palette extends CustomPalette {
		swamp: Palette["primary"];
		green: Palette["primary"];
		red: Palette["primary"];
		brown: Palette["primary"];
	}

	interface PaletteOptions extends CustomPalette {
		swamp?: PaletteOptions["primary"];
		green?: PaletteOptions["primary"];
		red?: PaletteOptions["primary"];
		brown?: PaletteOptions["primary"];
	}
}

// Update the Button's color options to include an ochre option
declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		swamp: true;
		green: true;
		red: true;
		brown: true;
	}
}

export const paletteTheme: ThemeOptions = {
	palette: {
		primary: {
			main: "#000",
		},
		secondary: {
			main: "#e1ff67",
		},
		apple: {
			main: "#FFF",
		},
		error: {
			main: "#ff0000",
		},
		warning: {
			main: "#fff100",
		},
		info: {
			main: "#00d4dc",
		},
		success: {
			main: "#00CF5D",
		},
		landingPage: {
			bg: "#F6F6F6",
			color: "#ffffff",
			cardBg: "#fff",
			cardColor: "#000000",
		},
		tipsPage: {
			bg: "#F6F6F6",
		},
		leagues: {
			bg: "#F6F6F6",
			primary: "#64FF32",
			secondary: "#008540",
			tint: "#222222",
		},
		header: {
			bg: "#ffffff",
			navBorderBottom: "#999",
			subNavLinkColor: "#999",
			subNavLinkActiveColor: "#000",
			subNavLinkActiveBorderColor: "#00cf5d",
			burgerMenuBg: "#000",
			burgerMenuCloseBtnColor: "#fff",
			burgerMenuLinkColor: "#fff",
		},
		login: {
			bg: "#ffffff",
			color: "#000000",
		},
		footer: {
			bg: "#000000",
			color: "#ffffff",
			linkColor: "rgba(255,255,255,0.7)",
		},
		banner: {
			liveBg: "#D0011B",
			liveColor: "#ffffff",
			liveCloseBg: "#ffffff",
			timerBg: "#000000",
			timerColor: "#ffffff",
			timerCloseBg: "#ffffff",
			savingBg: "#FF933C",
			savingColor: "#000000",
			savingCloseBg: "#000000",
			savedBg: "#00CF5D",
			savedColor: "#000000",
			savedCloseBg: "#ffffff",
		},
		roundSelector: {
			color: "#000000",
			bg: "#ffffff",
		},
		tipsCard: {
			bg: "#ffffff",
			dateColor: "#4D4D4D",
			color: "#000000",
			marginBg: "#EDEDED",
			oddsBg: "#2A66A6",
			oddsColor: "#ffffff",
			liveBg: "#D0011B",
			liveColor: "#ffffff",
		},
		tipsProfile: {
			linkColor: "#00CF5D",
		},
		green: {
			main: "#64FF32",
			light: "#76fe48",
			dark: "#5ae32e",
			contrastText: "#000000",
		},
		swamp: {
			main: "#008540",
			light: "#00994d",
			dark: "#007438",
			contrastText: "#ffffff",
		},
		red: {
			main: "#D0011B",
			light: "#ea0723",
			dark: "#b70016",
			contrastText: "#ffffff",
		},
		brown: {
			main: "#BAA381",
			light: "#d0b496",
			dark: "#aa9676",
			contrastText: "#000000",
		},
		modal: {
			bg: "#ffffff",
			color: "#000000",
		},
		ladder: {
			color: "#000000",
			slashColor: "#008540",
			divider: "#E6E6E6",
			bg: "#ffffff",
		},
		account: {
			bg: "#F6F6F6",
			formBg: "#ffffff",
		},
		sportsbetCalculator: {
			bg: "#0064A6",
			multiBg: "#EFBF13",
			color: "#2A66A6",
			stakeBg: "#ffffff",
			stakeColor: "#2A66A6",
		},
		help: {
			color: "#1A1A1A",
			bg: "#F6F6F6",
			navColor: "#1A1A1A",
			navBg: "#fff",
			navActiveBg: "#64FF32",
			contentColor: "#000",
			contentBg: "#fff",
		},
		rankings: {
			bg: "#F6F6F6",
			color: "#000000",
			tableBg: "#ffffff",
			tableColor: "#222222",
			tableUserBg: "#EDEDED",
			tableHeadBg: "#000000",
			tableHeadColor: "#ffffff",
		},
		tutorial: {
			color: "#000000",
			bg: "#ffffff",
			paginationBg: "#E6E6E6",
			activePaginationBg: "#64FF32",
		},
		secret: {
			headerBg: "#000",
			bg: "#001f0f",
			contentBg: "#fff",
			titleColor: "#0b1c17",
			subTitleColor: "#777",
			descColor: "#031d17",
			descHoverColor: "#fff",
			descHoverBg: "#000000CC",
			welcomeBackColor: "#fff",
			welcomeBackBg: "#000",
		},
		tournamentStatsModal: {
			bg: "#ffffff",
			dots: "#E6E6E6",
			activeDots: "#64FF32",
		},
		statsModal: {
			bg: "#ffffff",
			statsBg: "#EDEDED",
			color: "#000000",
			linkBg: "#64FF32",
			statsItemBg: "#ffffff",
		},
		chaserBar: {
			primary: "#222222",
			secondary: "#ffffff",
			active: "#64FF32",
		},
	},
};

type PaletteInterface = (typeof paletteTheme)["palette"];

declare module "@emotion/react" {
	export interface Theme {
		palette: NonNullable<PaletteInterface>;
	}
}
