import {first} from "lodash";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IBannerStore} from "data/stores/banner/banner.store";
import {BannerType} from "data/enums";
import {type IStreakPredictionsStore} from "data/stores/streak_predictions/streak_predictions.store";

export interface IStreakBannerController extends ViewController {
	readonly i18n: ILocalizationStore;

	get bannerType(): BannerType | null;
	get selectedRoundId(): number;
	get selectedRoundStartDate(): string;
	get nextRoundId(): number;
	get nextRoundStartDate(): string;
	get predictionsCount(): number;
	get totalPredictionsCount(): number;

	onComplete: () => void;
}

@injectable()
export class StreakBannerController implements IStreakBannerController {
	get bannerType() {
		return this._bannerStore.streakBannerType;
	}

	get selectedRoundId() {
		return this._roundsStore.selectedRound?.id || 1;
	}

	get selectedRoundStartDate() {
		return (
			first(this._roundsStore.selectedRound?.tournaments)?.date || new Date().toISOString()
		);
	}

	get predictionsCount() {
		return this._streakPredictionsStore.predictions.length;
	}

	get totalPredictionsCount() {
		return this._roundsStore.selectedRound?.tournaments.length || 0;
	}

	get nextRoundStartDate(): string {
		const firstScheduledRound = first(this._roundsStore.scheduleRounds);
		return first(firstScheduledRound?.tournaments)?.date || new Date().toISOString();
	}

	get nextRoundId() {
		return first(this._roundsStore.scheduleRounds)?.id || 1;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.StreakPredictionsStore)
		private _streakPredictionsStore: IStreakPredictionsStore,
		@inject(Bindings.BannerStore) private _bannerStore: IBannerStore
	) {
		makeAutoObservable(this);
	}

	onComplete = () => {
		this._bannerStore.onComplete();
	};

	dispose() {
		this._bannerStore.clearStore();
	}
}
