import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RoundStatus} from "data/enums";
import {type ISportsbetStore, SportsbetType} from "data/stores/sportsbet/sportsbet.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IBannerStore} from "data/stores/banner/banner.store";

const TOP_SCROLL_HEIGHT = 120;

interface IProps {
	isMobile: boolean;
	isTablet: boolean;
	scrollPosition: number;
}

export interface ITopActionsController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get hasPredictions(): boolean;
	get hasAllPredictions(): boolean;
	get isShowOdds(): boolean;
	get calculatorValue(): number;
	get roundStatus(): RoundStatus | undefined;
	/**
	 * Banner above gameBar
	 */
	get isShowBanner(): boolean;
	get isScheduled(): boolean;
	get isShowMobileShareButton(): boolean;
	get isMobile(): boolean;
	get isFixed(): boolean;
	get isTablet(): boolean;
	/**
	 * Banner in gameBar
	 */
	get isShowGameBarBanner(): boolean;
	get className(): string;

	autoPickHandler: () => void;
	reviewTipsHandler: () => void;
}

@injectable()
export class TopActionsController implements ITopActionsController {
	@observable private _isMobile: boolean = false;
	@observable private _isTablet: boolean = false;
	@observable private _scrollPosition: number = 0;

	get hasPredictions() {
		return this._predictionsStore.hasPredictions;
	}

	get hasAllPredictions() {
		return this._predictionsStore.hasAllPredictions;
	}

	get isShowOdds() {
		return this._sportsbetStore.getIsShow(SportsbetType.MultibetCalculator);
	}

	get calculatorValue() {
		return this._sportsbetStore.calculatorValue;
	}

	get roundStatus() {
		return this._roundStore.selectedRound?.status;
	}

	get isShowBanner() {
		return this._bannerStore.tippingBannerType !== null;
	}

	get isScheduled() {
		return this.roundStatus === RoundStatus.Scheduled;
	}

	get isShowMobileShareButton() {
		return this._isMobile && !this.isScheduled;
	}

	get isMobile() {
		return this._isMobile;
	}

	get isTablet() {
		return this._isTablet;
	}

	get isFixed() {
		return this._scrollPosition > TOP_SCROLL_HEIGHT;
	}

	get isShowGameBarBanner() {
		return !this.isTablet && this.isFixed;
	}

	get className() {
		return this.isShowGameBarBanner ? "full-width" : "";
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.SportsbetStore) private _sportsbetStore: ISportsbetStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore,
		@inject(Bindings.BannerStore) private _bannerStore: IBannerStore
	) {
		makeAutoObservable(this);
	}

	init(params: IProps) {
		this.onChange(params);
	}

	onChange({isMobile, scrollPosition, isTablet}: IProps) {
		this._isMobile = isMobile;
		this._scrollPosition = scrollPosition;
		this._isTablet = isTablet;
	}

	autoPickHandler = () => {
		this._modalsStore.showModal(ModalType.AUTO_PICK);
	};

	reviewTipsHandler = () => {
		this._modalsStore.showModal(ModalType.PREDICTIONS_SAVED);
	};
}
