export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum MatchStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum MatchType {
	Reg = "reg",
	So = "so",
	Playoff = "playoff",
}

export enum SquadType {
	Reg = "reg",
	So = "so",
}

export enum ModalType {
	ERROR,
	CONFIRM,
	GENERAL,
	AUTO_PICK,
	PREDICTIONS_SAVED,
	SHARE,
	JOIN_LEAGUE_INVITE,
	INVITE_VIA_EMAIL,
	GAME_STATS,
	STREAK_PREDICTIONS_SAVED,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
	Tips = "tips",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum AutoPickType {
	USER_FAVORITES = "userFavorites",
	LADDER_RANK = "ladderRank",
	SPORTS_BET = "sportsBet",
	HOME_TEAMS = "homeTeams",
}

export enum AutoPickSponsorType {
	SPORTS_BET = "sportsBet",
	NO_SPONSOR = "",
}

export enum TournamentIconType {
	EMPTY,
	CHECK,
	LOCK,
	SUCCESS,
	WRONG,
	AUTO_SUCCESS,
	AUTO_WRONG,
	DISABLED,
	SKIPPED,
	UN_CHECK,
}

export enum RankStatus {
	UP,
	DOWN,
	SAME,
}

export enum BannerType {
	Timer,
	NextRoundTimer,
	TipsSaving,
	TipsSaved,
	Live,
}

export enum OrderField {
	RANK = "rank",
	POINTS = "points",
	MARGIN = "margin",
	LONGEST_STREAK = "longestStreak",
	CURRENT_STREAK = "currentStreak",
	MISSED_GAMES = "missedGames",
}

export enum GameBannerType {
	STATE_OF_ORIGIN = "state_of_origin",
}

export enum SquadMatchResult {
	W = "W",
	L = "L",
	D = "D",
}

export enum StreakProgressType {
	NO_PREDICTIONS,
	HAS_PREDICTIONS,
	LIVE_WITH_PREDICTIONS,
	LIVE_WITH_OUT_PREDICTIONS,
	LIVE_LEADER,
}

export enum Gender {
	Male = "male",
	FEMALE = "female",
}
