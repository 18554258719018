import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import {type IRanking} from "data/stores/rankings/rankings.store";
import {RankStatus} from "data/enums";
import {getRankStatus} from "data/utils/getRankStatus";
import {isNumber} from "lodash";

interface IProps {
	ranking: IRanking;
	isShowRankStatus: boolean;
	isOverall: boolean;
}

export interface ITableRowController extends ViewController<IProps> {
	get className(): string;
	get rankStatus(): RankStatus | null;
	get lastNameInitial(): string;

	getValue: (value: number | null) => string | number;
	getRankValue: (value: number | null) => string;
}

@injectable()
export class TableRowController implements ITableRowController {
	@observable private _ranking: IRanking | null = null;
	@observable private _isShowRankStatus: boolean = false;
	@observable private _isOverall: boolean = false;

	get className() {
		return this.isMe ? "me" : "";
	}

	private get isMe() {
		return this._userStore.user?.id === this._ranking?.userId;
	}

	get rankStatus() {
		return getRankStatus({
			isShowRankStatus: this._isShowRankStatus,
			ranking: this._ranking,
			isOverall: this._isOverall,
		});
	}

	get lastNameInitial() {
		return this._ranking?.lastName?.at(0) || "";
	}

	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this.onChange(param);
	}

	onChange(param: IProps) {
		this._ranking = param.ranking;
		this._isShowRankStatus = param.isShowRankStatus;
		this._isOverall = param.isOverall;
	}

	getValue = (value: number | null) => value ?? "-";

	getRankValue = (value: number | null) =>
		isNumber(value) ? value.toLocaleString("en-US") : "-";
}
