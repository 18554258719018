import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {LOGOUT_LINK} from "data/constants";

export interface IHeaderController extends ViewController {
	readonly i18n: ILocalizationStore;

	get user(): IUser | null;
	get isOpen(): boolean;
	get isAuthorized(): boolean;

	closeHandler(): void;
	toggleMenu(): void;
	logoutHandler(): void;
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable private _disposer?: ReturnType<typeof reaction>;
	@observable private _isOpen = false;

	get user() {
		return this._userStore.user;
	}

	get isOpen() {
		return this._isOpen;
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);

		this._disposer = reaction(
			() => this.isOpen,
			(isOpen) => {
				const rootElement = document.querySelector("body");

				if (rootElement) {
					rootElement.style.overflow = isOpen ? "hidden" : "initial";
				}
			}
		);
	}

	toggleMenu = () => {
		this._isOpen = !this._isOpen;
	};

	closeHandler = () => {
		this._isOpen = false;
	};

	dispose() {
		this._disposer?.();
	}

	logoutHandler = async () => {
		await this._userStore.logout();
		window.location.assign(LOGOUT_LINK as string);
	};
}
