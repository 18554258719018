import React, {FC, Fragment} from "react";
import {NavLink, Outlet} from "react-router-dom";
import styled from "@emotion/styled";
import {footerLinks} from "data/constants/navigation";
import {Footer} from "views/components/footer/footer.component";
import {ReactComponent as NRLTippingWhite} from "assets/img/secret/tipping.svg";

const Header = styled.header`
	height: 55px;
	background: ${({theme}) => theme.palette.secret.headerBg};
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		height: 22px;
	}
`;

export const SecretLayout: FC = () => {
	return (
		<Fragment>
			<Header>
				<NavLink to="/">
					<NRLTippingWhite />
				</NavLink>
			</Header>

			<Outlet />
			<Footer links={footerLinks} />
		</Fragment>
	);
};
