import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {TippingLayout} from "views/components/layouts/tipping_layout/tipping_layout.component";
import {StreakLayout} from "views/components/layouts/streak_layout/streak_layout.component";
import {LandingLayout} from "views/components/layouts/landing_layout/landing_layout.component";
import {SportbetLayout} from "views/components/layouts/sportbet_layout/sportbet_layout.component";

const Home = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const LoginCallBack = lazy(
	retryFailLoad(() => import("views/pages/login_callback/login_callback.page"))
);
const MyTips = lazy(retryFailLoad(() => import("views/pages/my_tips/my_tips.page")));

const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.page")));
const JoinLeagues = lazy(retryFailLoad(() => import("views/pages/join_leagues/join_leagues.page")));
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.page"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.page")));
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.page"))
);
const LeagueInvite = lazy(
	retryFailLoad(() => import("views/pages/league_invite/league_invite.page"))
);
const LeagueAbout = lazy(retryFailLoad(() => import("views/pages/league_about/league_about.page")));
const LeagueTable = lazy(retryFailLoad(() => import("views/pages/league_table/league_table.page")));
const Rankings = lazy(retryFailLoad(() => import("views/pages/rankings/rankings.page")));
const Streak = lazy(retryFailLoad(() => import("views/pages/streak/streak.page")));
const StreakRankings = lazy(
	retryFailLoad(() => import("views/pages/streak_rankings/streak_rankings.page"))
);

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<SportbetLayout />}>
			<Route element={<LandingLayout />}>
				<Route index element={<Home />} />
			</Route>

			<Route element={<TippingLayout />}>
				<Route element={<NotAuthOnlyRoute />}>
					<Route path="/login/callback" element={<LoginCallBack />} />
				</Route>
				<Route element={<PrivateRoute />}>
					<Route path="/my-tips" element={<MyTips />} />
					<Route path="/my-account" element={<MyAccount />} />
					<Route path="/comps" element={<MyLeagues />} />
					<Route path="/comps/join" element={<JoinLeagues />} />
					<Route path="/comps/create" element={<CreateLeague />} />
					<Route path="/comp/:leagueId" element={<League />}>
						<Route path="manage" element={<LeagueInvite />} />
						<Route path="settings" element={<LeagueSettings />} />
						<Route path="about" element={<LeagueAbout />} />
						<Route path="ladder" element={<LeagueTable />} />
					</Route>
					<Route path="/rankings" element={<Rankings />} />
				</Route>

				<Route index path="/comps/join/:code" element={<JoinLeagues />} />
				<Route path="help/*" element={<Help />} />
				<Route path="*" element={<NotFound />} />
			</Route>

			<Route element={<StreakLayout />}>
				<Route element={<PrivateRoute />}>
					<Route path="/streak/my-tips" element={<Streak />} />
					<Route path="/streak/rankings" element={<StreakRankings />} />
				</Route>

				<Route path="/streak/help/*" element={<Help />} />
			</Route>
		</Route>

		<Route path="components" element={<ComponentsReview />} />
	</Routes>
);

export default RootRoutes;
