import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import {RankStatus} from "data/enums";
import {type ILeagueRanking} from "data/stores/league_rankings/league_rankings.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {getRankStatus} from "data/utils/getRankStatus";
import {isNumber} from "lodash";

interface IProps {
	ranking: ILeagueRanking;
	isShowRankStatus: boolean;
	isOverall: boolean;
}

export interface ILeagueTableRowController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get className(): string;
	get rankStatus(): RankStatus | null;
	get isMe(): boolean;
	get lastNameInitial(): string;

	getValue: (value: number | null) => string | number;
	getRankValue: (value: number | null) => string;
	getViewTipsItemCN: (index: number, length: number) => string;
}

@injectable()
export class LeagueTableRowController implements ILeagueTableRowController {
	@observable private _ranking: ILeagueRanking | null = null;
	@observable private _isShowRankStatus: boolean = false;
	@observable private _isOverall: boolean = false;

	get className() {
		return this.isMe ? "me" : "";
	}

	get isMe() {
		return this._userStore.user?.id === this._ranking?.userId;
	}

	get rankStatus() {
		return getRankStatus({
			isShowRankStatus: this._isShowRankStatus,
			ranking: this._ranking,
			isOverall: this._isOverall,
		});
	}

	get lastNameInitial() {
		return this._ranking?.lastName?.at(0) || "";
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this.onChange(param);
	}

	onChange(param: IProps) {
		this._ranking = param.ranking;
		this._isShowRankStatus = param.isShowRankStatus;
		this._isOverall = param.isOverall;
	}

	getValue = (value: number | null) => value ?? "-";

	getRankValue = (value: number | null) =>
		isNumber(value) ? value.toLocaleString("en-US") : "-";

	getViewTipsItemCN = (index: number, length: number) => (index + 1 === length ? "last" : "");
}
