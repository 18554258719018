import {injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {SECRET_KEY} from "data/constants";

interface IProps {
	searchParams: URLSearchParams;
}

export interface ISecretGateController extends ViewController<IProps> {
	get isSecretPassed(): boolean;
}

@injectable()
export class SecretGateController implements ISecretGateController {
	static get IS_SECRET_PASSED() {
		const IS_SECRET_ENABLED = Boolean(
			JSON.parse(process.env.REACT_APP_IS_SECRET_ENABLED || "false")
		);

		if (IS_SECRET_ENABLED) {
			return Boolean(JSON.parse(sessionStorage.getItem("isSecretPassed") || "false"));
		}

		return true;
	}

	get isSecretPassed() {
		return SecretGateController.IS_SECRET_PASSED;
	}

	init(params: IProps): void {
		if (SecretGateController.IS_SECRET_PASSED) {
			return;
		}

		const secret = params.searchParams.get("secret");

		if (SECRET_KEY === secret) {
			sessionStorage.setItem("isSecretPassed", "true");
			window.location.reload();
		}
	}
}
