import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {isNumber} from "lodash";
import {AxiosError} from "axios";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {IUser, type IUserStore} from "data/stores/user/user.store";
import {ModalType, RankStatus, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";
import {type IRankingApiProvider} from "data/providers/api/ranking.api.provider";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ISportsbetStore, SportsbetType} from "data/stores/sportsbet/sportsbet.store";
import {type IGameBar} from "data/types/game";
import {getLoginLink} from "data/utils/getLoginLink";
import {storageAvailable} from "data/utils/storageAvailable";
import {type IStreakGameBar} from "data/stores/streak_game_bar/streak_game_bar.store";
import {type IStreakRankingApiProvider} from "data/providers/api/streak_ranking.api.provider";

interface IParam {
	navigate: ReturnType<typeof useNavigate>;
}

export interface ILandingController extends ViewController<IParam> {
	i18n: ILocalizationStore;

	get user(): IUser | null;
	get isAuthorized(): boolean;
	get tippingGameBar(): IGameBar;
	get isLoading(): boolean;
	get isShowOdds(): boolean;
	get overallRankStatus(): RankStatus | null;
	get streakGameBar(): IStreakGameBar;

	redirectHandler: (path: string) => void;
	getSignUpLink: () => void;
}

@injectable()
export class LandingController implements ILandingController {
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	@observable private _requestState = RequestState.IDLE;
	@observable private _tippingGameBar: IGameBar = {
		roundRank: null,
		roundPoints: null,
		overallPoints: null,
		overallRank: null,
		prevOverallRank: null,
		tips: 0,
		tipsCount: 0,
		rankRoundId: 0,
		correctTips: 0,
	};
	@observable private _streakGameBar: IStreakGameBar = {
		longestStreak: 0,
		currentStreak: 0,
		leaderStreak: 0,
		missedGames: 0,
	};

	get user() {
		return this._userStore.user;
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get tippingGameBar() {
		return this._tippingGameBar;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get isShowOdds() {
		return this._sportsbetStore.getIsShow(SportsbetType.Logo);
	}

	get overallRankStatus() {
		if (
			!isNumber(this._tippingGameBar.prevOverallRank) ||
			!isNumber(this._tippingGameBar.overallRank)
		) {
			return null;
		}

		return this._tippingGameBar.prevOverallRank > this._tippingGameBar.overallRank
			? RankStatus.UP
			: RankStatus.DOWN;
	}

	get streakGameBar() {
		return this._streakGameBar;
	}

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RankingApiProvider) private _rankingApi: IRankingApiProvider,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SportsbetStore) private _sportsbetStore: ISportsbetStore,
		@inject(Bindings.StreakRankingApiProvider)
		private _streakRankingApi: IStreakRankingApiProvider
	) {
		makeAutoObservable(this);
	}

	@action redirectHandler = (path: string) => {
		if (this.isAuthorized) {
			this._navigate?.(path);
		} else {
			if (storageAvailable("sessionStorage")) {
				sessionStorage.setItem("redirectPath", path);
			}
			window.location.href = getLoginLink(false);
		}
	};

	@action getSignUpLink = () => {
		window.location.href = getLoginLink(true);
	};

	async init(param: IParam) {
		this._navigate = param.navigate;

		try {
			this._requestState = RequestState.PENDING;

			const requests: Promise<unknown>[] = [this._roundsStore.fetchRounds()];

			await Promise.all(requests);

			if (this.isAuthorized) {
				const requests = [
					this._rankingApi.gameBar({
						roundId: this._roundsStore.currentRound?.id,
					}),
					this._streakRankingApi.gameBar(),
				];

				const [tippingData, streakData] = await Promise.all(requests);

				runInAction(() => {
					this._tippingGameBar = tippingData.data.success as IGameBar;
					this._streakGameBar = streakData.data.success as IStreakGameBar;
				});
			}

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this._requestState = RequestState.ERROR;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	}
}
