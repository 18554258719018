import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {DateTime} from "luxon";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {RoundStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";

export interface IStreakGameController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isAuthorized(): boolean;
	get currentRound(): IRound | undefined;
	get isShowScheduledBlock(): boolean;
	get currentRoundStartDate(): string;
	get isPlaying(): boolean;
	get isComplete(): boolean;
}

@injectable()
export class StreakGameController implements IStreakGameController {
	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get currentRound() {
		return this._roundStore.currentRound;
	}

	get currentRoundStartDate() {
		return this._roundStore.currentRoundStartDate || "";
	}

	get isScheduled() {
		return this.currentRound?.status === RoundStatus.Scheduled;
	}

	get isShowScheduledBlock() {
		if (!this.currentRoundStartDate) {
			return false;
		}

		const startDate = DateTime.fromISO(this.currentRoundStartDate);
		return this.isScheduled && startDate > DateTime.now();
	}

	get isPlaying() {
		return this.currentRound?.status === RoundStatus.Playing;
	}

	get isComplete() {
		return this.currentRound?.status === RoundStatus.Complete;
	}

	constructor(
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}
}
