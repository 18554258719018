import React, {FC, Fragment} from "react";
import {Outlet} from "react-router-dom";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {streakFooterLinks} from "data/constants/navigation";
import {Header} from "views/components/header/header/header.component";
import {Footer} from "views/components/footer/footer.component";
import {IStreakLayoutController} from "./streak_layout.controller";
import {ReactComponent as LogoIcon} from "assets/img/nrlTippingStreak.svg";

export const StreakLayout: FC = observer(() => {
	const {subNavItems, burgerNavItems} = useViewController<IStreakLayoutController>(
		Bindings.StreakLayoutController
	);

	return (
		<Fragment>
			<Header LogoIcon={LogoIcon} subNavItems={subNavItems} burgerNavItems={burgerNavItems} />
			<Outlet />
			<Footer links={streakFooterLinks} />
		</Fragment>
	);
});
