import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {MatchStatus, ModalType, RequestState} from "data/enums";
import {get, isEqual} from "lodash";
import {useLocation, useNavigate} from "react-router-dom";
import {extractErrorMessage} from "data/utils";
import {type ILeagueRankingsStore} from "data/stores/league_rankings/league_rankings.store";
import {ChangeEvent} from "react";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

interface IProps {
	leagueId: number;
	isMobile: boolean;
	location: ReturnType<typeof useLocation>;
	navigate: ReturnType<typeof useNavigate>;
}

interface ITab {
	path: string;
	name: string;
}

export interface ILeagueController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get isLoading(): boolean;
	get isCommissioner(): boolean;
	get tabs(): ITab[];
	get activeIndex(): number;
	get isShowViewTipsSwitch(): boolean;
	get viewTipsSwitchVal(): boolean;

	changeViewTipsHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class LeagueController implements ILeagueController {
	@observable private _location?: ReturnType<typeof useLocation>;
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	@observable private _fetchLeagueDisposer?: ReturnType<typeof reaction>;
	@observable private _leagueId?: number;
	@observable private _requestState = RequestState.PENDING;
	@observable private _isMobile = false;

	get tabs() {
		if (!this.league) return [];

		const id = this.league.id;

		const tabs = [
			{
				path: `/comp/${id}/ladder`,
				name: this.i18n.t("league_nav.tab.ladder", "Ladder"),
			},
		];

		// If isn't Team Supported league add manage tab
		if (this.league.code) {
			tabs.push({
				path: `/comp/${id}/manage`,
				name: this.i18n.t("league_nav.tab.manage", "Manage"),
			});
		}

		if (this.isCommissioner) {
			tabs.push({
				path: `/comp/${id}/settings`,
				name: this.i18n.t("league_nav.tab.settings", "Settings"),
			});
		} else {
			tabs.push({
				path: `/comp/${id}/about`,
				name: this.i18n.t("league_nav.tab.about", "About"),
			});
		}

		return tabs;
	}

	@computed get activeIndex() {
		const index = this.tabs.findIndex((tab) => isEqual(tab.path, this._location?.pathname));

		return index === -1 ? 0 : index;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isCommissioner() {
		return isEqual(this.league?.leagueManager?.userId, this._userStore.user!.id);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isFinishAnyTournament() {
		return this._roundsStore.viewTipsRounds
			.filter(({id}) => id >= get(this.league, "startId", 1))
			.some(({tournaments}) =>
				tournaments.some(({status}) => status === MatchStatus.Complete)
			);
	}

	get isShowViewTipsSwitch() {
		if (!this.isFinishAnyTournament) {
			return false;
		}

		return this._location?.pathname.split("/").pop() === "ladder" && this._isMobile;
	}

	get viewTipsSwitchVal() {
		return this._leagueRankingsStore.isViewTips;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeagueRankingsStore) private _leagueRankingsStore: ILeagueRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action onSuccessLeagueRequest = () => {
		this._requestState = RequestState.SUCCESS;

		const hasValidTab = this.tabs.some((tab) => isEqual(tab.path, this._location?.pathname));

		if (!hasValidTab) {
			const path = this.isCommissioner ? "settings" : "about";
			this._navigate?.(`/comp/${this._leagueId!}/${path}`, {replace: true});
		}
	};

	@action onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action onChange({leagueId, navigate, location, isMobile}: IProps) {
		this._leagueId = leagueId;
		this._location = location;
		this._navigate = navigate;
		this._isMobile = isMobile;
	}

	@action init(params: IProps) {
		this.onChange(params);

		this._fetchLeagueDisposer = reaction(
			() => this._leagueId,
			() => {
				if (!this._leagueId) return;

				this._requestState = RequestState.PENDING;

				void this._leaguesStore
					.fetchLeague({leagueId: this._leagueId})
					.then(this.onSuccessLeagueRequest)
					.catch(this.onError);
			},
			{fireImmediately: true}
		);
	}

	changeViewTipsHandler = (e: ChangeEvent<HTMLInputElement>) => {
		this._leagueRankingsStore.updateViewTips(e.target.checked);
	};

	dispose() {
		this._fetchLeagueDisposer?.();
	}
}
