import React, {FC, Fragment} from "react";
import {partial} from "lodash";
import styled from "@emotion/styled";
import {Radio} from "@mui/material";
import {observer} from "mobx-react";
import {nth} from "data/utils";
import {getSquadAvatarUrl} from "data/utils/avatar";
import {ITournamentData} from "data/stores/tournament/tournament.store";
import {Exist} from "views/components/exist/exist.component";
import {ICardPredictorStats} from "./card_predictor_stats/card_predictor_stats.component";

const FlexCol = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 6px;

	img {
		width: 40px;
		height: 40px;
		object-fit: contain;
	}
`;

const HomeTeamSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	@media (min-width: 375px) {
		gap: 4px;
	}
`;

const AwayTeamSection = styled(HomeTeamSection)`
	justify-content: flex-end;
`;

const TeamName = styled.div`
	display: block;
	color: ${({theme}) => theme.palette.tipsCard.color};
	text-align: center;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 50px;

	@media (min-width: 375px) {
		max-width: 55px;
	}

	@media (min-width: 425px) {
		max-width: 80px;
	}

	@media (min-width: 500px) {
		max-width: none;
	}
`;

const Position = styled.p`
	color: #4d4d4d;
	text-align: center;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	min-height: 16px;

	@media (min-width: 769px) {
		font-size: 14px;
	}
`;

const SRadio = styled(Radio)`
	svg {
		width: 24px;
		height: 24px;
	}
`;

interface ICardPredictor {
	tournament: ITournamentData;
	statsClickHandler?: () => void;
	changeHandler: (squadId: number) => void;
	StatsComponent: FC<ICardPredictorStats>;
	hasPrediction?: boolean;
}

export const CardPredictor: FC<ICardPredictor> = observer(
	({tournament, changeHandler, statsClickHandler, StatsComponent, hasPrediction}) => {
		const {
			isDisabled,
			homeSquad,
			homeSquadId,
			homeSquadName,
			homeScore,
			awaySquad,
			awaySquadId,
			awaySquadName,
			awayScore,
			isScheduled,
			isPlaying,
			isComplete,
			isShowLadderPosition,
		} = tournament;
		const HomeIcon = homeSquad.Icon;
		const AwayIcon = awaySquad.Icon;

		return (
			<Fragment>
				<HomeTeamSection
					onClick={partial(changeHandler, homeSquadId)}
					className="team-section">
					<SRadio
						onClick={partial(changeHandler, homeSquadId)}
						icon={<HomeIcon />}
						checkedIcon={<HomeIcon />}
						checked={homeSquad.isChecked}
						disabled={isDisabled}
						// aria-label={homeSquad.title}
						title={homeSquad.title}
					/>
					<FlexCol>
						<TeamName>{homeSquadName}</TeamName>
						<img src={getSquadAvatarUrl(homeSquadId)} alt={homeSquadName} />

						<Position>
							<Exist when={isShowLadderPosition}>
								{homeSquad.position}
								{nth(homeSquad.position)}
							</Exist>
						</Position>
					</FlexCol>
				</HomeTeamSection>

				<StatsComponent
					clickHandler={statsClickHandler}
					isPlaying={isPlaying}
					isScheduled={isScheduled}
					isComplete={isComplete}
					homeSquad={homeSquad}
					homeScore={homeScore}
					awayScore={awayScore}
					awaySquad={awaySquad}
					hasPrediction={hasPrediction}
				/>

				<AwayTeamSection
					onClick={partial(changeHandler, awaySquadId)}
					className="team-section">
					<FlexCol>
						<TeamName>{awaySquadName}</TeamName>
						<img src={getSquadAvatarUrl(awaySquadId)} alt={awaySquadName} />

						<Position>
							<Exist when={isShowLadderPosition}>
								{awaySquad.position}
								{nth(awaySquad.position)}
							</Exist>
						</Position>
					</FlexCol>
					<SRadio
						onClick={partial(changeHandler, awaySquadId)}
						icon={<AwayIcon />}
						checkedIcon={<AwayIcon />}
						checked={awaySquad.isChecked}
						disabled={isDisabled}
						aria-label={awaySquad.title}
						title={awaySquad.title}
					/>
				</AwayTeamSection>
			</Fragment>
		);
	}
);
