import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IUserStore} from "data/stores/user/user.store";
import {LOGOUT_LINK} from "data/constants";
import {burgerMenuLinks} from "data/constants/navigation";
import {IBurgerNavItem} from "data/types/navigation";

export interface IBurgerNavController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isAuthorized(): boolean;
	get navItems(): IBurgerNavItem[];

	logout(): Promise<void>;
}

@injectable()
export class BurgerNavController implements IBurgerNavController {
	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get navItems() {
		return burgerMenuLinks.filter(({isPrivate}) =>
			isPrivate ? this._userStore.isAuthorized : true
		);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	logout = async () => {
		await this._userStore.logout();
		window.location.assign(LOGOUT_LINK as string);
	};
}
