import {v4 as uuidv4} from "uuid";
import pkceChallenge from "pkce-challenge";
import {
	REACT_APP_SSO_CLIENT_ID,
	REACT_APP_SSO_REDIRECT_URI,
	REACT_APP_SSO_URL,
} from "data/constants";
import {storageAvailable} from "./storageAvailable";

const {code_challenge, code_verifier} = pkceChallenge(128);

export const getLoginLink = (isSignUp: boolean) => {
	const nonce: string = uuidv4();
	const myState: string = uuidv4();

	if (storageAvailable("localStorage")) {
		localStorage.setItem("code_verifier", code_verifier);
		localStorage.setItem("code_challenge", code_challenge);
		localStorage.setItem("myState", myState);
		localStorage.setItem("nonce", nonce);
	}

	const AUTHORISE_API = `${REACT_APP_SSO_URL}/account/authorize?`;
	const params = [
		`client_id=${REACT_APP_SSO_CLIENT_ID}`,
		"response_type=code",
		`redirect_uri=${REACT_APP_SSO_REDIRECT_URI}`,
		"grant_type=authorization_code",
		// REMOVED THESE PARAMS TO MATCH EXISTING TIPPING PARAMS
		// need to check with NRL if they are able to update to accept
		// code challenge and state params
		// `code_challenge=${code_challenge}`,
		// `state=${myState}`,
		// "code_challenge_method=S256",
		`scope=openid email profile offline_access country ${isSignUp ? "signup" : ""}`,
	];
	return `${AUTHORISE_API}${params.join("&")}`;
};
